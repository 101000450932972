//* ******* reset & normalize *******  *//

// box-sizing the same for all elements
html {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    margin: 0;
    padding: 0;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-style: normal;
}
ol,
ul {
    list-style: none;
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none;
}

//table
table {
    border-collapse: collapse;
    border-spacing: 0;
}
td,
th {
    padding: 0;
}

//forms element
input {
    outline: none;
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px white inset;
    }
}
textarea {
    overflow: auto;
    max-width: 100%;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: button;
    cursor: pointer;
    outline: none;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
input {
    line-height: normal;
}
input[type='search'] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
    -webkit-appearance: none;
}

//link
a {
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
        outline: 0;
        color: #32DC59;
    }
}

i {
    font-style: italic;
}
b,
strong {
    font-weight: 700;
}

// images
img {
    width: auto;
    max-width: 100%;
    height: auto;
    vertical-align: top;
    border: 0;
}

.hidden {
    display: none !important;
}
