.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    > div {
        > span {
            font-size: 16px;
            color: #fff;
        }
        > button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 29px;
            height: 29px;
            font-size: 15px;
            color: #fff;
            background: transparent;
            border: none;
            position: relative;
            svg {
                fill: #ffff;
                width: 18px;
            }
            &:disabled {
                svg {
                    fill: #fff;
                }
            }
        }
        > input {
            border: none;
            font-size: 14px;
            max-width: 65px;
            color: #fff;
            background-color: rgba(#7694fe, 0.1);
            padding: 8px 15px;
            border-radius: 5px;
            text-align: center;
            &::-webkit-input-placeholder {
                color: #fff;
            }
            &::-moz-placeholder {
                color: #fff;
                opacity: 1;
            }
            &::-ms-input-placeholder {
                color: #fff;
            }
        }
    }
    .left_position {
        display: flex;
        align-items: center;
        > span {
            margin-right: 12px;
        }
        > input {
            margin-left: 12px;
        }
    }
    .right_position {
        > span {
            margin-right: 8px;
            &.title_go_to_block {
                margin-left: 22px;
            }
        }
        > input {
            max-width: 65px;
            margin-right: 7px;
            &.input_go_to_block {
                max-width: 90px;
            }
        }
    }
    &.trans_pool {
        .right_position {
            > span {
                &:first-child {
                    //margin-right: 0;
                }
            }
        }
        input {
            transition: 0.25s;
            &:active,
            &:focus {
                //max-width: 30px;
            }
        }
        .of {
            //color: #9099a1;
        }
    }
}
