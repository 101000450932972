.block_details {
    margin-bottom: 30px;
    margin-top: 35px;
}
.heading_block {
    display: flex;
    align-items: flex-end;
    padding-bottom: 15px;
    @include max991 {
        flex-wrap: wrap;
    }
    .currency_name {
        font-size: 28px;
        font-weight: 300;
        color: #fff;
    }
    .height_block {
        font-size: 28px;
        font-weight: 300;
        color: #fff;
        margin: 0 25px;
        display: flex;
        > a {
            svg {
                fill: #ffff;
                width: 18px;
            }
            &.prev_block {
                margin-right: 5px;
            }
            &.next_block {
                margin-left: 5px;
            }
        }
    }
    .hash_block {
        font-size: 14px;
        color: #fff;
        //font-weight: 300;
        text-transform: uppercase;
        @include max991 {
            padding-top: 12px;
        }
        @include max575 {
            @include textWrap;
            line-height: 1.4;
        }
    }
}
.wrapper_table_block_details {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 13px;
    @include max761 {
        flex-direction: column;
    }
    table {
        width: 50%;
        table-layout: fixed;

        @include max761 {
            width: 100%;
        }
        tr {
            height: 38px;
            &.block_type {
                .pos,
                .pow {
                    padding: 2px 12px;
                    color: #fff;
                    font-size: 14px;
                }
                .pos {
                    background-color: #32DC59;
                }
                .pow {
                    background-color: #474747;
                }
            }
            &:nth-child(2n + 1) {
                //background-color: rgba(#206F5A, 1);
            }
            &:nth-child(2n) {
                //background-color: rgba(#206F5A, 0.1);
            }
            td {
                white-space: nowrap;
                padding: 12px 20px;
            }
            &.tr_empty {
                @include max761 {
                    display: none;
                }
            }
            &.cumulative_diff_presize,
            &.cumulative_diff_adjusted,
            &.effective_txs_median,
            &.current_txs_median,
            &.total_transactions_size {
                td {
                    @include max575 {
                        white-space: normal;
                        line-height: 1.4;
                    }
                }
            }
            &.tr_seed {
                height: 50px;
                td:last-child {
                    @include textWrap;
                }
            }
            &.transactions_fee {
                height: 50px;
            }
        }

        &.left_table {
            td {
                &:first-child {
                    width: 50%;
                    @include max761 {
                        width: 40%;
                    }
                    @include max575 {
                        width: 50%;
                    }
                }
                &:last-child {
                    padding-right: 80px;
                    width: 65%;
                    @include max991 {
                        padding-right: 20px;
                    }
                    @include max761 {
                        width: 60%;
                    }
                    @include max575 {
                        width: 50%;
                    }
                }
            }
        }
        &.right_table {
            td {
                &:first-child {
                    padding-left: 80px;
                    width: 50%;
                    @include max991 {
                        padding-left: 60px;
                        width: 60%;
                    }
                    @include max575 {
                        width: 50%;
                    }
                    @include max761 {
                        padding-left: 20px;
                        width: 40%;
                    }
                    @include max575 {
                        width: 50%;
                    }
                }
                &:last-child {
                    width: 45%;
                    @include max991 {
                        width: 25%;
                    }
                    @include max761 {
                        width: 60%;
                    }
                    @include max575 {
                        width: 50%;
                    }
                }
            }
        }
    }
}

.block_transactions_list {
    .title {
        height: 45px;
        display: flex;
        align-items: center;
        font-size: 24px;
        color: #fff;
        font-weight: 300;
    }
    .table_block_transaction {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
    }
    thead {
        //background-color: rgba(#fff, .2);
        background: linear-gradient(to right, #1b3a8a, #206F5A);
        th {
            @include max575 {
                padding: 12px;
            }
        }
    }
    tbody {
        tr {
            &:nth-child(2n) {
                //background-color: rgba(#206F5A, 0.1);
            }
            td {
                @include max575 {
                    padding: 12px;
                }
            }
            .hash {
                @include max991 {
                    > a,
                    > span {
                        display: block;
                        width: 280px;
                        @include max761 {
                            width: 190px;
                        }
                        @include max575 {
                            width: 100px;
                        }
                        @include mobile {
                            width: 85px;
                        }
                    }
                    > a {
                        @include text-truncate; // block-details
                    }
                    > span {
                        @include textWrap; // alt-block-details
                    }
                }
            }
            .fee {
            }
            .total_amount {
            }
            .size {
            }
        }
    }
}
