header {
    display: flex;
    align-items: center;
    height: 80px;
    position: relative;
    #mobile-nav-btn {
        display: none;
        width: 22px;
        height: 16px;
        background-image: $mobileMenuIco;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-color: transparent;
        border: none;
        @include max761 {
            display: flex;
            position: absolute;
            right: 0;
        }
    }
    nav {
        @include max761 {
            display: none;
        }
        ul {
            display: flex;
            > li {
                margin-right: 45px;
                > a {
                    font-size: 16px;
                    font-weight: 400;
                    color: #fff;
                    &.active {
                        font-weight: 600;
                        color: #fff;
                    }
                }
            }
        }
    }
    .logo{
        width: 140px;
        height: 140px;
        margin-right: 3%;
        //background-image: $logo;
        //background-repeat: no-repeat;
        //background-position: 0 center;
        font-size: 26px;
        font-weight: 700;
        display: flex;
        align-items: center;
        color: #fff;
//        text-transform: uppercase;
        padding-left: 60px;
        cursor: pointer;
        @include unSelect;
        outline: none;
        @include max575 {
            margin-right: 10px;
        }
    }

    .case_testnet_mainnet {
        position: absolute;
        right: 0;
        height: 40px;
        display: flex;
        align-items: center;
        border-radius: 10px;
        overflow: hidden;
        font-size: 14px;
        @include max761 {
            right: 80px;
        }
        @include max575 {
            font-size: 13px;
        }
        @include mobile {
            display: none;
        }
        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 0 15px;
            //cursor: pointer;
            background-color: #03131F;
            color: #fff;
            @include unSelect;
            @include max575 {
                padding: 0 10px;
            }
            &.testnet {
            }
            &.mainnet {
            }
            &.active {
                color: #fff;
                background: linear-gradient(to right, #1b3a8a, #206F5A);
                font-weight: 600;
            }
        }
    }
}
