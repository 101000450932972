// special font - (svg icons)(
@font-face {
    font-family: 'EngraversGothic';
    src:url('assets/fonts/EngraversGothic.ttf') format('truetype'),
        url('assets/fonts/EngraversGothic.svg#EngraversGothic') format('svg');
    font-weight: normal;
    font-style: normal;
}

// $variables
// $background: url('assets/img/background.jpg');
//$logo: url('assets/img/logo.svg');
//$logo: url('assets/img/logo-site.png');
$font: 'Open Sans', sans-serif;
$mobileMenuIco: url('assets/img/menu.svg');
$showIco: url('assets/img/show.svg');
$search: url('./assets/img/search-general.svg');
$backBtn: url('./assets/img/back-block.svg');
$searchSecond: url('./assets/img/search-second.svg');

.base-icon {
    display: inline-block;
    font-size: 49px;
    position: relative;
    &:before {
        font-family: 'EngraversGothic';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        position: absolute;
    }
}

// BASE
@import 'assets/styles/base/null';
@import 'assets/styles/base/base';
@import 'assets/styles/base/mixins';

// LAYOUT
@import 'assets/styles/layout/main';
@import 'assets/styles/layout/header';

// MODULES
@import 'assets/styles/modules/main-info';
@import 'assets/styles/modules/table';
@import 'assets/styles/modules/forms';
@import 'assets/styles/modules/pagination';
@import 'assets/styles/modules/loader';
@import 'assets/styles/modules/block-details';

.fixed_background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // height: calc(100% + 60px);
    // // background-image: $background;
    background-repeat: no-repeat;
    // background-position: center center;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    // background-attachment: fixed;
    // z-index: -1;
}

// search component (btn-search)
app-search {
    > button {
        display: flex;
        width: 25px;
        height: 100%;
        flex-shrink: 0;
        background-image: $search;
        background-repeat: no-repeat;
        background-position: center center;
    }
}

.back_btn {
    width: 80px;
    height: 13px;
    display: flex;
    align-items: center;
    padding-left: 21px;
    background-image: $backBtn;
    background-repeat: no-repeat;
    background-position: 3px center;
    color: #fff;
    font-size: 16px;
    @include max575 {
        &.hide_back_btn {
            display: none;
        }
    }
}

// alias search
.alias_input_search {
    width: 100%;
    height: 33px;
    border: none;
    font-size: 14px;
    color: #fff;
    padding-left: 47px;
    background-image: $search;
    background-repeat: no-repeat;
    background-position: 12px center;
    background-size: 18px;
    @include max761 {
        font-size: 16px;
    }
}

.wrap_charts {
    width: 100%;
    background-color: #3b3b3b;
    position: relative;
    padding: 20px 0;
    @include max991 {
        overflow: auto;
    }
    .wrap_loader {
        height: 700px;
    }
}

.dialog-open {
    overflow: hidden;
}

// webkit visible scroll
::-webkit-scrollbar {
    -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
    width: 10px;
}
::-webkit-scrollbar:horizontal {
    height: 10px;
}
::-webkit-scrollbar-thumb {
    //background-color: rgba(0, 0, 0, .3);
    background-color: rgba(#4e4e4e, 1);
    border: 1px solid #ffffff;
}
::-webkit-scrollbar-track {
    background-color: #ffffff;
}

.highcharts-range-selector {
    font-size: 13px !important;
    &:focus {
        border: none !important;
        top: 11px !important;
        margin-left: 2px;
        color: #fff !important;
    }
}
app-charts {
    .highcharts-range-selector-group {
        display: none !important;
    }
}
.ngx-json-viewer {
    padding: 0 5px;
    .segment .segment-main .segment-key {
        color: #fff !important;
    }
}
.wrapper{
    width: 40px;
    height: 40px;
    position: relative;
    padding: 20px;
  } 
  .back{
    transform: rotateY(180deg);
  }
  .front, .back{
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
  .front{
      animation: front-rotation 5s infinite;
    }
  .back{
      animation: back-rotation 5s infinite;
    }
  
  @keyframes front-rotation{
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
  @keyframes back-rotation{
    from {
      transform: rotateY(180deg);
    }
    to {
      transform: rotateY(540deg);
    }
  }
