@mixin text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
@mixin textWrap {
    white-space: normal;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    line-break: strict;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}
@mixin coverBox {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
@mixin abs($top: auto, $right: auto, $bottom: auto, $left: auto) {
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
    position: absolute;
}
@mixin coverImg {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
}
@mixin valingBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@mixin unSelect {
    -webkit-touch-collout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}
@mixin max1199 {
    // maket 1171
    @media (max-width: 1199px) {
        @content;
    }
}
@mixin max1170 {
    // makets 992
    @media (max-width: 1170px) {
        @content;
    }
}
@mixin max991 {
    // makets 762
    @media (max-width: 991px) {
        @content;
    }
}
@mixin max761 {
    // makets 576
    @media (max-width: 761px) {
        @content;
    }
}
@mixin max575 {
    // makets 400
    @media (max-width: 575px) {
        @content;
    }
}
@mixin mobile {
    @media (max-width: 399px) {
        @content;
    }
}
@mixin triangleUp($w: 20px, $h: 20px, $color: red) {
    width: 0;
    height: 0;
    border: $w solid transparent;
    border-bottom: $h solid $color;
}
@mixin triangleDown($w: 20px, $h: 20px, $color: red) {
    width: 0;
    height: 0;
    border-left: $w solid transparent;
    border-right: $w solid transparent;
    border-top: $h solid $color;
}
@mixin triangleLeft($w: 20px, $h: 20px, $color: red) {
    width: 0;
    height: 0;
    border-top: $w solid transparent;
    border-bottom: $w solid transparent;
    border-right: $h solid $color;
}
@mixin triangleRight($w: 20px, $h: 20px, $color: red) {
    width: 0;
    height: 0;
    border-top: $w solid transparent;
    border-bottom: $w solid transparent;
    border-left: $h solid $color;
}
@mixin triangleTopLeft($w: 20px, $h: 20px, $color: red) {
    width: 0;
    height: 0;
    border-top: $w solid $color;
    border-right: $h solid transparent;
}
@mixin parallelogram($w: 150px, $h: 100px, $skew: 20deg, $color: red) {
    width: $w;
    height: $h;
    -webkit-transform: skew($skew);
    -moz-transform: skew($skew);
    -o-transform: skew($skew);
    background: $color;
    > span,
    a,
    address {
        -webkit-transform: skew(-$skew);
        -moz-transform: skew(-$skew);
        -o-transform: skew(-$skew);
    }
}
@mixin bottomLine($h: 8px, $color: #00b9bf) {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: $color;
    width: 100%;
    height: $h;
}
@mixin center-block {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
@mixin icoCenter {
    background-repeat: no-repeat;
    background-position: center center;
}
@mixin pseudo($display: block, $pos: absolute, $content: '') {
    content: $content;
    display: $display;
    position: $pos;
}
@mixin bartblock {
    background-color: #206F5A;
}
