.container {
    width: 100%;
    //max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto 30px;
    height: 100%;
    text-align: center;
}
main {
    overflow: hidden;
}
.top_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 77px;

    .left_box {
        flex-basis: 50%;
        @include max1170 {
            flex-basis: 60%;
        }

        .mobile_heading {
            display: none;
            color: #fff;
            font-size: 20px;
            @include max761 {
                display: flex;
            }
            @include max575 {
                &.hide_heading {
                    display: none;
                }
            }
        }

        .blockchain_info {
            @include max761 {
                display: none;
            }
        }
    }
    app-search &.aliases_top_box {
        height: 77px;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0 25px;
        @include max761 {
            height: 127px;
        }
        > div {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }
        .row_heading_search {
            height: 32px;
            align-items: center;
        }
        .wrap_search {
            height: 33px;
        }
    }
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 180px;
    font-size: 20px;
    font-weight: 300;
    color: #fff;
    border-radius: 10px;
    background-color: rgba(35, 78, 226, 0.1);
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(#fff, 0.4);
    z-index: 999;
    @include max575 {
        background-color: #fff;
    }
}

.blockchain-visualization {
    width: 100%;
    padding: 20px 0;
    overflow-x: hidden;
    position: relative;
    padding-left: 3%;
    
    .chain {
        display: flex;
        align-items: center;
        padding: 0 20px;
        flex-direction: row; // Изменяем направление для правильного добавления новых блоков
        justify-content: flex-start;
        
        .block {
            min-width: 150px;
            height: 100px;
            margin-right: 30px;
            padding: 15px;
            border-radius: 8px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            position: relative;
            transition: all 0.3s ease;
            will-change: transform, opacity;
            flex-shrink: 0; 
            
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
            }
            
            &:not(:last-child):after {
                content: '';
                position: absolute;
                right: -30px;
                top: 50%;
                width: 30px;
                height: 2px;
                background: linear-gradient(to right, #666, #999);
                z-index: 1;
            }
            
            // Стили для PoS блоков
            &.pos-block {
                background: linear-gradient(135deg, #4CAF50, #45a049);
                color: white;
                
                &.highlight-new {
                    animation: highlightPosBlock 2000ms ease-out;
                }
            }
            
            &.pow-block {
                background: linear-gradient(135deg, #2196F3, #1976D2);
                color: white;
                
                &.highlight-new {
                    animation: highlightPowBlock 2000ms ease-out;
                }
            }
            
            // Добавляем стили для анимации подсветки
            &.highlight {
                position: relative;
                
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 8px;
                    background: rgba(28, 193, 41, 0.3);
                    pointer-events: none;
                }
            }
            
            .block-header {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: 8px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            
            .block-hash {
                font-size: 12px;
                opacity: 0.9;
                word-break: break-all;
                margin-bottom: 8px;
            }
            
            .block-type {
                position: absolute;
                bottom: 10px;
                right: 10px;
                font-size: 12px;
                padding: 3px 8px;
                border-radius: 12px;
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

@keyframes highlightPosBlock {
    0% {
        box-shadow: 0 0 20px 10px #1CC129;
    }
    100% {
        box-shadow: 0 0 0 0 #1CC129;
    }
}

@keyframes highlightPowBlock {
    0% {
        box-shadow: 0 0 20px 10px #1CC129;
    }
    100% {
        box-shadow: 0 0 0 0 #1CC129;
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(100%);
    }
    to {
        transform: translateX(0);
    }
}

// Добавим медиа-запрос для адаптивности
@media (max-width: 768px) {
    .blockchain-visualization {
        .chain {
            .block {
                min-width: 120px;
                height: 80px;
                margin-right: 20px;
                padding: 10px;
                
                &:not(:last-child):after {
                    right: -20px;
                    width: 20px;
                }
                
                .block-header {
                    font-size: 14px;
                }
                
                .block-hash {
                    font-size: 10px;
                }
                
                .block-type {
                    font-size: 10px;
                }
            }
        }
    }
}