@import 'mixins';

html,
body {
    margin-top: 1%;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
}
body {
    font-family: $font;
    background: #030B15;

    // background-image: $background;
    background-repeat: no-repeat;
    // background-size: 100% 100%;
    // background-size: cover;
    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-attachment: scroll;
}
input,
textarea,
button {
    font-family: $font;
}
