.main_info {
    display: flex;
    width: 17%;
    flex-direction: column;
    justify-content: space-between;
    //background-color: #206F5A;
    border: 2px solid #32DC59;
    overflow: hidden;
    @include max991 {
        //flex-direction: column;
        width: 90%;
    }
    > div {
        flex-basis: 25%;
        @include max991 {
            display: flex;
            flex-basis: 100%;
            align-items: flex-start;
            padding: 12px 24px;
            //border-top: 1px solid rgba(#fff, .4);
            &:nth-child(2n + 1) {
                background-color: #292929;  // fix for mobile ver
            }
            @include max575 {
                padding: 12px 20px;
            }
            @include mobile {
                padding-right: 5px;
            }
        }
        .title {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            font-size: 16px;
            color: #fff;
            @include max991 {
                flex-basis: 50%;
                height: auto;
                font-size: 14px;
                justify-content: flex-start;
                text-transform: uppercase;
                background-color: transparent;
                color: #fff;
            }
            @include max575 {
                //flex-basis: 40%;
                padding-right: 15px;
            }
            @include mobile {
                //font-size: 12px;
            }
        }
        .value {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 87px;
            color: #fff;
            font-weight: 300;
            background-color: #03131F;
            @include max991 {
                flex-basis: 50%;
                height: auto;
                justify-content: flex-start;
                color: #fff;
                font-weight: 400;
                background-color: transparent;
            }
        }
        .distribution_coins {
            //display: block;
            .coin {
                margin-top: 0.5rem;
            }
        }
        &.height {
            flex-basis: 20%;
            @include max991 {
                flex-basis: 100%;
            }
            .value {
                font-size: 25px;
                @include max1170 {
                    font-size: 20px;
                }
                @include max991 {
                    font-size: 14px;
                }
            }
        }
        &.difficulty {
            flex-basis: 30%;
            @include max991 {
                flex-basis: 100%;
            }
            .value {
                flex-direction: column;
                font-size: 16px;
                @include max1170 {
                    font-size: 14px;
                }
                @include max991 {
                    align-items: flex-start;
                }
                > div {
                    display: flex;
                    position: relative;
                    padding: 5px 0 5px 17px;
                    > span {
                        padding-right: 5px;
                    }
                    &:before {
                        @include pseudo;
                        top: 7px;
                        left: 0;
                        border: 11px solid transparent;
                        border-bottom: 0;
                        border-left: 11px solid transparent;
                        @include max991 {
                            top: 6px;
                        }
                    }
                    &.pos {
                        &:before {
                            border-left-color: #32DC59;
                        }
                    }
                    &.pow {
                        &:before {
                            border-left-color: #474747;
                        }
                    }
                }
            }
        }
        &.coins_emitted {
            .value {
                font-size: 20px;
                @include max1170 {
                    font-size: 18px;
                }
                @include max991 {
                    font-size: 14px;
                }
            }
        }
        &.transactions {
            flex-basis: 20%;
            @include max991 {
                flex-basis: 100%;
            }
            .value {
                font-size: 25px;
                @include max1170 {
                    font-size: 20px;
                }
                @include max991 {
                    font-size: 14px;
                }
            }
        }
        &.hash_rate {
            .value {
                font-size: 25px;
                @include max1170 {
                    font-size: 20px;
                }
                @include max991 {
                    font-size: 14px;
                }
            }
        }
    }
}
